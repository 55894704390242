
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    modelValue: {
      type: Number,
      required: false,
    },
  },
  setup(props, context) {
    const onInput = (event: Event) => {
      const value: string = (event.currentTarget as HTMLInputElement).value
      context.emit('update:modelValue', value === '' ? null : Number(value))
    }

    return {
      onInput,
    }
  },
})
