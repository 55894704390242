<template>
  <div class="box">
    <h2 class="title is-3">欲しいもの登録</h2>
    <div class="field">
      <app-label>名称</app-label>
      <div class="control has-icons-left">
        <app-input-text v-model="wish.name"></app-input-text>
        <span class="icon is-small is-left">
          <i class="fas fa-pen"></i>
        </span>
      </div>
    </div>
    <div class="field">
      <app-label>金額</app-label>
      <div class="columns">
        <div class="column is-4-desktop is-12-mobile">
          <div class="control has-icons-left">
            <app-input-number
              v-model.number="wish.price"
              :step="1000"
            ></app-input-number>
            <span class="icon is-small is-left">
              <i class="fas fa-yen-sign"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <!-- <app-label>写真</app-label>
    <app-input-image
      @file="onFileSelected"
      :maxHeight="1000"
      :maxWidth="1000"
    ></app-input-image>
 -->
    <div class="field">
      <app-label>URL</app-label>
      <input type="url" v-model.number="wish.url" />
    </div>

    <div class="field is-grouped is-grouped-centered">
      <div class="control">
        <button @click="onCancel" class="button is-link is-light">
          キャンセル
        </button>
      </div>
      <div class="control">
        <button @click="onSubmit" type="button" class="button is-primary">
          登録する
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  PropType,
  reactive,
  ref,
  watch,
  inject,
  ComputedRef,
} from 'vue'

import { Wish } from '@/types/wishlist'
import AppLabel from '@/components/controls/AppLabel.vue'
import AppInputText from '@/components/controls/AppInputText.vue'
import AppInputNumber from '@/components/controls/AppInputNumber.vue'
// import AppInputImage from '@/components/controls/AppInputImage.vue'
// import { User } from '@/types/user'
// import firebase from 'firebase/app'
import 'firebase/storage'
// import { v1 as uuidv1 } from 'uuid'
// import { getExtension } from '@/helpers/file'

export default defineComponent({
  components: { AppLabel, AppInputText, AppInputNumber },
  props: {
    roomId: {
      type: String,
      required: true,
    },
    modelValue: {
      type: Object as PropType<Wish>,
      required: true,
    },
  },
  emits: ['submit', 'cancel', 'update:modelValue'],
  setup(props, context) {
    // const storage = firebase.storage()

    // const image = ref<File>()

    // Create a storage reference from our storage service
    // const storageRef = storage.ref()
    // TODO
    // const user = inject<ComputedRef<User>>('user')
    // console.log(user)

    let wish = reactive(props.modelValue)

    const onSubmit = () => {
      /*
      if (image.value && user) {
        // Image is upload.
        const filename = uuidv1() + '.' + getExtension(image.value.name)
        const originalName = image.value.name

        const fileRef = storageRef.child(
          `users/${user.value.uid}/rooms/${props.roomId}/${filename}`
        )
        // console.log(user, `${user.value.uid}/${file.name}`, fileRef)

        fileRef
          .put(image.value, {
            customMetadata: { originalFileName: originalName },
          })
          .then((snapshot) => {
            wish.image = {
              name: originalName,
              path: snapshot.metadata.fullPath,
            }
            console.log('upload file', snapshot)
            context.emit('submit')
          })
      } else {
        context.emit('submit')
      } */
      context.emit('submit')
    }

    const onCancel = () => context.emit('cancel')
    /*
    const onFileSelected = (file: File) => {
      console.log('onFileSelected call', file)
      // if (!user) {
      //   return
      // }

      image.value = file

      /*
      // const ext = getExtension(file.name)
      const filename = uuidv1() + '.' + getExtension(file.name)

      const fileRef = storageRef.child(
        `users/${user.value.uid}/rooms/${props.roomId}/${filename}`
      )
      // console.log(user, `${user.value.uid}/${file.name}`, fileRef)

      fileRef.put(file).then(async (snapshot) => {
        wish.image = {
          name: file.name,
          path: snapshot.metadata.fullPath,
        }
        console.log('upload file', snapshot)
      })

    }
    */
    /*
    const onFileSelected = (e: Event) => {
      // console.log('test', e)s
      const target: HTMLInputElement = e.target as HTMLInputElement

      if (!target.files) {
        return
      }

      if (!user) {
        return
      }

      const file = target.files[0]

      const fileRef = storageRef.child(
        `users/${user.value.uid}/rooms/${props.roomId}/${file.name}`
      )
      // console.log(user, `${user.value.uid}/${file.name}`, fileRef)

      fileRef.put(file).then(async (snapshot) => {
        wish.image = {
          url: await snapshot.ref.getDownloadURL(),
          path: snapshot.metadata.fullPath,
        }
        console.log('upload file', snapshot)
      })

      const reader = new FileReader()
      reader.readAsDataURL(file)

      reader.onload = () => {
        const readed = {
          title: file.name,
          size: file.size,
          lastModifiedDate: file.lastModified,
          mime_type: file.type,
          base64: reader.result,
        }

        console.log(readed)
      }

      // let file = (<HTMLInputElement>e.target).files[0];
      // const target = <HTMLInputElement>e.target
      // if (target) {
      //   return;
      // }
      // const render = new FileReader()
      // render.readAsDataURL(target.files[0])
      // let baseFile = ''
      // render.onload = () => {
      //   baseFile = render.result
      //   const readed = {
      //     title: target.files[0].name,
      //     size: target.files[0].size,
      //     lastModifiedDate: target.files[0].lastModifiedDate,
      //     mime_type: target.files[0].type,
      //     base64: baseFile,
      //   }
      //   // // バリデーション
      //   // if (this.validate(readed)) {
      //   //   this.uploaded = readed;
      //   //   this.$emit('input', this.uploaded);
      //   // }
      // }
    }
*/
    watch(wish, () => {
      context.emit('update:modelValue', wish)
    })

    return {
      wish,
      onSubmit,
      onCancel,
      // onFileSelected,
      // user,
    }
  },
})
</script>
