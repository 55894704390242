
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    modelValue: {
      type: String,
      required: false,
    },
  },
  setup(props, context) {
    const onInput = (event: Event) => {
      context.emit(
        'update:modelValue',
        (event.currentTarget as HTMLInputElement).value
      )
    }

    return {
      onInput,
    }
  },
})
