<template>
  <input
    type="number"
    :value="modelValue"
    @input="onInput"
    class="input is-2"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    modelValue: {
      type: Number,
      required: false,
    },
  },
  setup(props, context) {
    const onInput = (event: Event) => {
      const value: string = (event.currentTarget as HTMLInputElement).value
      context.emit('update:modelValue', value === '' ? null : Number(value))
    }

    return {
      onInput,
    }
  },
})
</script>

<style lang="scss" scoped>
// // Remove step control.
// input::-webkit-outer-spin-button,
// input::-webkit-inner-spin-button {
//   -webkit-appearance: none;
//   margin: 0;
// }

// /* Firefox */
// input[type='number'] {
//   -moz-appearance: textfield;
// }
</style>
