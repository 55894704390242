<template>
  <wish-form
    v-model="wish"
    :roomId="roomId"
    @submit="onSubmit"
    @cancel="onCancel"
  ></wish-form>
</template>

<script lang="ts">
import { defineComponent, reactive, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Wish } from '@/types/wishlist'
import firebase from 'firebase/app'
import 'firebase/firestore'
import { wishConverter } from '@/converter/wishlistConverter'
import WishForm from '@/components/wish/WishForm.vue'

export default defineComponent({
  components: { WishForm },
  setup() {
    const db = firebase.firestore()
    const route = useRoute()
    const router = useRouter()

    const { roomId, wishId } = route.params
    // let wish: Wish = reactive({ name: '' })
    let wish: Partial<Wish> = reactive({})

    const onSubmit = () => {
      db.collection('rooms')
        .doc(roomId as string)
        .collection('wishes')
        .withConverter(wishConverter)
        .doc(wishId as string)
        .set(wish as Wish)
      router.push({ name: 'WishListShow', params: { id: roomId } })
    }

    const onCancel = () => {
      router.push({ name: 'WishListShow', params: { id: roomId } })
    }

    const getWish = async () => {
      console.log('getiws')
      const result = await db
        .collection('rooms')
        .doc(roomId as string)
        .collection('wishes')
        .withConverter(wishConverter)
        // TODO 強引すぎる
        .doc(wishId as string)
        .get()

      // const data = result.data()
      // console.log(data)
      // if (data) {
      //   wish = Object.assign(wish, data)
      // }
      return result.data()
    }
    onMounted(async () => {
      // TODO このやり方でいいのだろうか。。
      wish = Object.assign(wish, await getWish())
    })

    return {
      roomId,
      wish,
      getWish,
      onSubmit,
      onCancel,
    }
  },
})
</script>
